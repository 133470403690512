export function getRandomIntInclusive(min, max) {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled);
}

export const localStorageClear = () => {
  const abTestHash = localStorage.getItem("abTestHash");

  localStorage.clear();

  localStorage.setItem("abTestHash", abTestHash);
}